import { getAnalytics, Fragments, WebTracking } from 'common';
import { transformUserForSignUpStep } from './segmentTransformers';

export const signUpStarted = async (email: string) => {
	const analytics = await getAnalytics();
	analytics?.web.signupStarted({ email });
};

export const onboardingStarted = async (email: string) => {
	const analytics = await getAnalytics();
	//TODO: use correct segment event
	analytics?.web.signupStarted({ email });
};

export const signUpStepCompleted = async (
	user: Pick<
		Fragments.UserFieldsFragment,
		'email' | 'firstName' | 'lastName' | 'specialities' | 'addresses' | 'id'
	>,
	step: WebTracking.SignupStepCompletedStep
) => {
	const analytics = await getAnalytics();
	const userForSignUpStep = transformUserForSignUpStep(user, step);
	analytics?.web.signupStepCompleted(userForSignUpStep);
};

export const signUpCompleted = async () => {
	const analytics = await getAnalytics();
	analytics?.web.signupCompleted({});
};
